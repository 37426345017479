import React from "react";

import OrderItem from "./OrderItem";

export default function AllOrders({
  order,
  setReasonSelect,
  onChange,
  setArticle,
  setPositionselect,
  positionSelect,
  reasonSelect,
  setComment,
  emptyComment,
  setReplace,
}) {
  const allPositions = order.map((order, index) => (
    <OrderItem
      key={index}
      articleName={order.articleName}
      quantity={order.quantity}
      onChange={onChange}
      setArticle={setArticle}
      index={index}
      id={order.id}
      articleNumber={order.articleNumber}
      setPositionselect={setPositionselect}
      setReasonSelect={setReasonSelect}
      positionSelect={positionSelect}
      price={order.price}
      reasonSelect={reasonSelect}
      setComment={setComment}
      emptyComment={emptyComment}
      setReplace={setReplace}
    ></OrderItem>
  ));

  return (
    <div>
      <ul className="divide-y divide-gray-200 px-5 md:px-2">{allPositions}</ul>
    </div>
  );
}
