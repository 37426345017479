import { ChevronLeftIcon } from "@heroicons/react/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
export default function BackButton() {
  let history = useHistory();
  const { t } = useTranslation();
  return (
    <button
      onClick={() => history.goBack()}
      type="button"
      className="z-10 absolute bottom-0 left-0 inline-flex items-center px-3 py-2 leading-4 font-medium rounded-md text-gray-600  hover:underline "
    >
      <ChevronLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      {t("order.button2")}
    </button>
  );
}
