import { API } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "../../Components/Login/Loading";
import i18n from "../../i18n";
import createDate from "../../Util/createDate";
import BackButton from "../Generic/BackButton";
import { Context } from "../GlobalState/GlobalState";
import Tutorial from "../Orders/Tutorial";
import CheckRetour from "./CheckRetour";
import DHLLabel from "./DHLLabel";
import Display from "./Display";
import Notification from "./Notification";
export default function RetourOverview() {
  const [dhlData, setDhlData] = useState();
  const [loaded, setloaded] = useState(false);
  const [state] = useContext(Context);
  const allProducts = state.allData.data.details;
  const selectChecker = state.productData;
  const reasonData = state.reasonData;
  const replace = state.replace;
  const [showLabel, setShowLabel] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (showLabel === false) {
      getOrder();
      setTimeout(() => {
        setloaded(true);
      }, 2000);
    }
  }, [showLabel]);

  useEffect(() => {
    if (loaded) {
      postRetour();
    }
  }, [loaded]);
  state;
  async function getOrder() {
    const getCurrentLng = () =>
      i18n.language || window.localStorage.i18nextLng || "";
    const order = state.allData.data;
    const shipping = order.shipping;
    const parameters = {
      queryStringParameters: {
        receiverId: "deu",
        customerReference: "Kundenreferenz",
        shipmentReference: "Sendungsreferenz",
        name1: `${shipping.firstName + " " + shipping.lastName}`,
        name2: "Empfänger Retoure Zeile 2",
        name3: "Empfänger Retoure Zeile 3",
        streetName: shipping.street,
        houseNumber: "111",
        postCode: shipping.zipCode,
        city: shipping.city,
        countryISOCode: shipping.country.iso,
        country: shipping.country.iso,
        orderNumber: order.number,
        email: order.customer.email,
        date: createDate(order.orderTime),
        lang: getCurrentLng(),
      },
    };
    await API.get("laessigAPI", "/dhl", parameters).then((response) =>
      setDhlData(response)
    );
  }

  async function postRetour() {
    const data = state.allData.data;

    let positions = [];
    allProducts.map((element) => {
      if (selectChecker[`${element.id}`] > 0) {
        return positions.push(element);
      }
    });

    let params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers":
          "Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
      },
      body: {
        number: data.customer.number,
        userId: data.customer.number,
        orderId: data.number,
        statusId: data.orderStatusId,
        dhlLabel: dhlData.shipmentNumber,
        comment: state.comments,
        positions: positions,
        reason: reasonData,
        replace: replace,
        quantity: selectChecker,
      },
    };

    await API.post("laessigAPI", "/postretour", params);
  }

  return (
    <div className="container mx-auto lg:max-w-screen-md mt-10">
      <div className=" relative bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div className="px-4 py-5 sm:px-6 bg-gray-50">
          <BackButton></BackButton>
          <h1 className="text-md text-donkey font-bold">
            {t("overview.header")}
          </h1>
        </div>

        <div className="px-4 py-4 sm:px-6 ">
          <Tutorial></Tutorial>
          {loaded ? <Notification></Notification> : null}
        </div>
        <Display
          allProducts={allProducts}
          selectChecker={selectChecker}
          reasonData={reasonData}
          replace={replace}
        ></Display>

        {showLabel ? (
          <div>
            <CheckRetour
              data={state.allData}
              setShowLabel={setShowLabel}
            ></CheckRetour>
          </div>
        ) : (
          <div>
            <div className="flex justify-center pt-4">
              {loaded ? (
                <DHLLabel
                  label={dhlData}
                  data={allProducts}
                  info={state.allData.da}
                ></DHLLabel>
              ) : (
                <Loading
                  className="ml-4 -mr-1 h-8 w-8"
                  type={"bubbles"}
                  color={"#614b40"}
                  height={50}
                  width={50}
                ></Loading>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
