import { ChevronRightIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import createDate from "../../Util/createDate";
import BackButton from "../Generic/BackButton";
import { Context } from "../GlobalState/GlobalState";
import AllOrders from "./AllOrders";
import NoSelectModal from "./NoSelectModal";
import Tutorial from "./Tutorial";
export default function OrderPage() {
  const commentReducer = (state, event) => {
    return {
      ...state,
      [event.target.id]: event.target.value,
    };
  };
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const [article, setArticle] = useState([]);
  const [positionSelect, setPositionselect] = useState([]);
  const [reasonSelect, setReasonSelect] = useState([]);
  const [checkSelect, setCheckSelect] = useState(true);
  const [comment, setComment] = useReducer(commentReducer, {});
  const [noSelect, setNoSelect] = useState(false);
  const [replace, setReplace] = useState([]);
  const history = useHistory();
  const order = state.allData.data;
  const replaceData = [];
  replaceData;

  useEffect(() => {
    checkComment();
  }, [collectData]);
  replace;
  function collectData() {
    const check = [];
    for (const [, value] of Object.entries(positionSelect)) {
      check.push(value);
    }

    const higher = (element) => element > 0;

    checkComment();
    if (check.some(higher) && noSelect) {
      dispatch({ type: "SET_ORDERS", payload: article });
      dispatch({ type: "SET_REASONS", payload: reasonSelect });
      dispatch({ type: "SET_COMMENTS", payload: comment });
      dispatch({ type: "SET_REPLACE", payload: replace });
      history.push("/overview");
    } else {
      setCheckSelect(false);
    }
  }

  function checkComment() {
    const check = [];
    for (const [, value] of Object.entries(reasonSelect)) {
      check.push(value);
    }
    const other = (element) => element === "Sonstiges";
    if (check.some(other)) {
      for (const [key] of Object.entries(reasonSelect)) {
        if (comment[key]) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      setNoSelect(true);
    }
  }

  //observes Input selects
  function onChange(event, index) {
    setArticle((prevState) => ({ ...prevState, [index]: event }));
  }

  return (
    <div className="container mx-auto lg:max-w-screen-md mt-10">
      <div className="relative pb-1 bg-white overflow-visible shadow rounded-lg bg-gray-50 ">
        <div className="px-4 py-5 sm:px-6">
          <BackButton></BackButton>
          <div className="flex justify-around items-center">
            <h1 className="text-md text-donkey font-bold">
              {t("order.header")}
            </h1>
            <div className="text-xs">
              <h3 className="text-gray-500">
                {t("order.text1")}: {order.number}
              </h3>
              <h3 className="text-gray-500">
                {t("order.text2")}: {createDate(order.orderTime)}
              </h3>
            </div>
          </div>
        </div>
        <div className="px-4  bg-white">
          <div className="border-b-2">
            <Tutorial></Tutorial>
          </div>
          <Context.Provider value={replaceData}>
            <AllOrders
              order={order.details}
              onChange={onChange}
              setPositionselect={setPositionselect}
              setReasonSelect={setReasonSelect}
              positionSelect={positionSelect}
              reasonSelect={reasonSelect}
              setComment={setComment}
              setReplace={setReplace}
            ></AllOrders>
          </Context.Provider>
        </div>

        <div className="flex justify-center m-4 relative">
          <button
            onClick={collectData}
            type="button"
            className="min-w-max inline-flex items-center px-2 md:px-4 py-2 border border-shop shadow-sm text-white font-medium rounded-md bg-shop hover:bg-donkey hover:border-donkey focus:ring-shop focus:ring-2 focus:ring-offset-0"
          >
            {t("order.button1")}
            <ChevronRightIcon
              className=" ml-1 md:ml-3 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </button>
          {checkSelect ? null : (
            <NoSelectModal setCheckSelect={setCheckSelect}></NoSelectModal>
          )}
        </div>
      </div>
    </div>
  );
}
