import { ClipboardCopyIcon } from "@heroicons/react/solid";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CheckRetour({ setShowLabel }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex md:justify-center justify-around items-center p-5 relative mb-3 md:m-0 bg-gray-50">
        <button
          onClick={() => setShowLabel(false)}
          type="button"
          className="inline-flex items-center px-2  py-2.5 md:px-4 md:py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-shop hover:bg-donkey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-donkey"
        >
          <ClipboardCopyIcon
            className="-ml-1 mr-3 h-5 w-5"
            aria-hidden="true"
          />
          {t("overview.button1")}
        </button>
      </div>
    </div>
  );
}
