import React, { createContext, useReducer } from "react";

const initialState = [];

const GlobalState = ({ children }) => {
  const Reducer = (state, action) => {
    switch (action.type) {
      case "SET_ALL_DATA":
        return {
          ...state,
          allData: action.payload,
        };
      case "SET_ORDERS":
        return {
          ...state,
          productData: action.payload,
        };
      case "SET_REASONS":
        return {
          ...state,
          reasonData: action.payload,
        };
      case "SET_COMMENTS":
        return {
          ...state,
          comments: action.payload,
        };
      case "SET_REPLACE":
        return {
          ...state,
          replace: action.payload,
        };

      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
export const Context = createContext(initialState);
export default GlobalState;
