import md5 from "md5";
export function generateImage(num) {
  const crypted = md5(`media/image/${num}_1.jpg`);
  const str1 = crypted.substring(0, 2);
  const str2 = crypted.substring(2, 4);
  const str3 = crypted.substring(4, 6);
  const code = `${str1}/${str2}/${str3}`;

  return `https://cdn.laessig-fashion.de/media/image/${code}/${num}_1.jpg`;
}
