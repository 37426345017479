import React, { useEffect, useState } from "react";

import { generateImage } from "../../Util/generateImage";
import DropDownQuantity from "./General/DropDownQuantity";
import DropDownReason from "./General/DropDownReason";
import Position from "./General/Position";
import TextArea from "./TextArea";

export default function OrderItem({
  articleName,
  index,
  quantity,
  setReasonSelect,
  onChange,
  id,
  articleNumber,
  setPositionselect,
  positionSelect,
  setComment,
  price,
  setReplace,
  emptyComment,
}) {
  const [url, setURL] = useState();
  const [other, setOther] = useState(false);
  useEffect(() => {
    setURL(generateImage(articleNumber));
  }, []);

  return (
    <li key={index} className=" py-4 md:divide-none">
      <div className="md:grid  md:grid-cols-2 sm:gap-5 sm:items-start">
        <Position
          name={articleName}
          number={articleNumber}
          price={price}
          quantity={quantity}
          url={url}
        ></Position>
        <div className="md:flex  mt-3.5">
          <div className=" py-2 md:py-0">
            <DropDownQuantity
              quantity={quantity}
              onChange={onChange}
              index={index}
              setPositionselect={setPositionselect}
              id={id}
            ></DropDownQuantity>
          </div>

          <div
            className={`${
              positionSelect[id] > 0
                ? "visible"
                : "hidden md:block md:invisible"
            } px-2 pt-3 md:pt-0`}
          >
            <DropDownReason
              setReasonSelect={setReasonSelect}
              id={id}
              setOther={setOther}
              setReplace={setReplace}
            ></DropDownReason>
          </div>
        </div>
      </div>
      {other ? (
        <TextArea
          setComment={setComment}
          setReasonSelect={setReasonSelect}
          articleNumber={articleNumber}
          emptyComment={emptyComment}
          id={id}
        ></TextArea>
      ) : null}
    </li>
  );
}
