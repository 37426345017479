import React from "react";

export default function TextArea({ setComment, id, emptyComment }) {
  return (
    <div className="mt-4 md:mt-0 px-2 sm:grid sm:grid-cols-2 sm:gap-5 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 md:pt-0">
      <div className="mt-1 sm:mt-0 col-start-2 col-span-1 focus:ring-donkey focus:border-donkey">
        <label
          htmlFor="about"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2"
        >
          Bitte sonstigen Grund angeben
        </label>
        <textarea
          id={id}
          name={`${id}-comment`}
          rows={3}
          onChange={setComment}
          className="max-w-lg shadow-sm block w-full focus:ring-donkey focus:border-donkey sm:text-sm border border-gray-300 rounded-md"
          defaultValue={""}
        />
        {typeof emptyComment !== "undefined" && emptyComment[id] ? (
          <p className="mt-2 text-sm text-gray-500">
            Write a few sentences about yourself.
          </p>
        ) : null}
      </div>
    </div>
  );
}
