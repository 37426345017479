import {
  ExclamationCircleIcon,
  ExclamationIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { API } from "aws-amplify";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NotEmpty } from "../../Util/Validation/FormValidation";
import LanguageSwitch from "../Generic/LanguageSwitch";
import { Context } from "../GlobalState/GlobalState";
import Loading from "./Loading";
const orderReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

export default function LoginPage() {
  const { t } = useTranslation();
  const [order, setOrder] = useState();
  const [userData, setUserData] = useReducer(orderReducer, {});
  const [verified, setVerified] = useState(true);
  const [loading, setLoading] = useState(false);
  const [plzInput, setPlzInput] = useState(true);
  const [orderInput, setOrderInput] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  // in future versions the API gets called from userData.bestellnummer
  useEffect(() => {
    if (order && !firstUpdate.current) {
      setTimeout(() => {
        isVerified();
      }, 2000);

      dispatch({ type: "SET_ALL_DATA", payload: order });
    }
  }, [order]);
  useEffect(() => {
    if (!firstUpdate.current) {
    }
  }, [loading]);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  useEffect(() => {
    if (redirect) {
      history.push("/order");
    }
  }, [redirect]);

  useEffect(() => {
    if (userData.bestellnummer !== "" && userData.bestellnummer !== undefined) {
      setOrderInput(true);
    }
    if (userData.plz !== "" && userData.plz !== undefined) {
      setPlzInput(true);
    }
  }, [userData]);

  async function handleOrder() {
    const parameters = {
      queryStringParameters: {
        orderNumber: userData.bestellnummer,
        zipCode: userData.plz,
      },
    };
    let orderValid = await NotEmpty.isValid(userData.bestellnummer);
    let plzValid = await NotEmpty.isValid(userData.plz);
    setLoading(true);
    if (orderValid && plzValid === true) {
      setOrderInput(true);
      setPlzInput(true);
      await API.get("laessigAPI", "/order", parameters)
        .then((response) => {
          setOrder(response);
        })

        .catch(() => {
          setLoading(false);
          setVerified(false);
        });
    } else if (!orderValid && !plzValid) {
      setOrderInput(false);
      setLoading(false);
      setPlzInput(false);
    } else if (!plzValid) {
      setPlzInput(false);
      setLoading(false);
    } else if (!orderValid) {
      setOrderInput(false);
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  //verify ordernumber and plz
  function isVerified() {
    if (order === "Empty") {
      setVerified(false);
    } else {
      setVerified(true);
      setLoading(false);
      setRedirect(true);
    }
  }
  // render next page // reject error

  return (
    <div className="container mx-auto lg:max-w-screen-md mt-10">
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 ">
        <div className="px-4 py-5 sm:px-6 bg-gray-50">
          <LanguageSwitch></LanguageSwitch>
          <h1 className="text-center text-lg font-bold text-donkey">
            {t("login.header")}
          </h1>
        </div>
        {verified ? null : (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{t("login.error")}</p>
              </div>
            </div>
          </div>
        )}
        <div className="px-4 py-5 sm:p-6 ">
          <div className="leading-7 ">
            <p>{t("login.text0")}</p>
            <p>{t("login.text1")}</p>
            <p>
              {t("login.text2")}{" "}
              <a
                className="font-bold underline "
                target="_blank"
                href={t("login.link")}
                rel="noreferrer"
              >
                {t("login.text3")}
              </a>
              {t("login.text4")}
            </p>
            <p className="mt-1">{t("login.text5")}</p>
            <p>{t("login.text6")}</p>
          </div>
          <div>
            <div className="mt-4 border border-donkey rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-donkey focus-within:border-donkey">
              <label
                htmlFor="bestellnummer"
                className="block text-xs font-medium text-gray-900 font-light"
              >
                {t("Deine Bestellnummer")}
              </label>
              <input
                type="text"
                name="bestellnummer"
                id="bestellnummer"
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 outline-none sm:text-sm"
                placeholder="00000"
                onChange={setUserData}
              />
            </div>

            {orderInput ? null : (
              <div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute top-2 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {t("Bitte gib eine Bestellnummer ein.")}
                </p>
              </div>
            )}

            <div className="mt-4 border border-donkey rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-donkey focus-within:border-donkey">
              <label
                htmlFor="plz"
                className="block text-xs font-medium text-gray-900"
              >
                {t("Deine Postleitzahl")}
              </label>
              <input
                type="text"
                name="plz"
                id="plz"
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 outline-none sm:text-sm font-light"
                placeholder="00000"
                onChange={setUserData}
              />
            </div>
            {plzInput ? null : (
              <div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 top-2 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {t("Bitte gib deine Postleitzahl ein.")}
                </p>
              </div>
            )}
          </div>
          <div className="mt-8 flex justify-center">
            <button
              onClick={() => {
                handleOrder();
              }}
              type="button"
              className="inline-flex items-center px-8 py-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white  bg-shop hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-donkey"
            >
              {t("login.button")}
              {loading ? (
                <Loading
                  className="ml-4 -mr-1 h-5 w-5"
                  type={"bubbles"}
                  color={"#614b40"}
                  height={30}
                  width={30}
                ></Loading>
              ) : (
                <SearchIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
