import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import GlobalState from "./Components/GlobalState/GlobalState";
import Loading from "./Components/Login/Loading";
import LoginPage from "./Components/Login/LoginPage";
import OrderPage from "./Components/Orders/OrderPage";
import RetourOverview from "./Components/RetourOverview/RetourOverview";

function App() {
  return (
    <Suspense fallback={<Loading></Loading>}>
      <Router>
        <GlobalState>
          <Switch>
            <LoginPage path="/" exact></LoginPage>
            <OrderPage path="/order" exact></OrderPage>
            <RetourOverview path="/overview"></RetourOverview>
          </Switch>
        </GlobalState>
      </Router>
    </Suspense>
  );
}

export default App;
