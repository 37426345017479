import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { generateImage } from "../../Util/generateImage";
import Position from "../Orders/General/Position";
export default function Article({
  name,
  number,
  price,
  quantity,
  reason,
  replace,
}) {
  const [url, setURL] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    setURL(generateImage(number));
  }, []);
  const reasons = [
    { id: 1, name: `${t("order.reason.1")}` },
    { id: 2, name: `${t("order.reason.2")}` },
    { id: 3, name: `${t("order.reason.3")}` },
    { id: 4, name: `${t("order.reason.4")}` },
    { id: 5, name: `${t("order.reason.5")}` },
    { id: 6, name: `${t("order.reason.6")}` },
    { id: 7, name: `${t("order.reason.7")}` },
    { id: 8, name: `${t("order.reason.8")}` },
    { id: 9, name: `${t("order.reason.9")}` },
    { id: 10, name: `${t("order.reason.10")}` },
    { id: 11, name: `${t("order.reason.11")}` },
  ];
  reason;
  reasons[2].name;
  return (
    <div className="px-4 py-5 sm:p-6 ">
      <div className="flex md:justify-between items-start flex-col md:grid md:flex-row md:px-3 py-1 md:py-0 md:gap-0 grid-cols-3">
        <div className="col-span-2">
          <Position
            name={name}
            number={number}
            price={price}
            quantity={quantity}
            url={url}
          ></Position>
        </div>
        <div className="md:ml-2 mt-5 md:mt-0 col-start-3">
          <h4 className="font-bold ">{t("Rückgabegrund")}:</h4>
          <p className="text-gray-700">{reason}</p>
        </div>
        {[reasons[2].name, reasons[6].name, reasons[7].name].includes(
          reason
        ) ? (
          <div className="md:ml-2 mt-3 md:mt-0 col-start-3">
            <h4 className="font-bold ">{t("Ersatzartikel")}:</h4>
            <p className="text-gray-700">{replace}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
