import React from "react";
import { useTranslation } from "react-i18next";
export default function Position({ name, price, quantity, number, url }) {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="font-bold ">{name}</h1>
      <div className="flex mt-3 md:mt-1">
        <div className="mr-4 flex-shrink-0">
          <img className="h-16 w-16" src={url} alt="baby Dress"></img>
        </div>
        <div className="tracking-normal">
          <p className="mt-1 md:mt-0.5 text-gray-700">
            {t("Preis")}: {price} €
          </p>
          <p className="mt-1 md:mt-0.5 text-gray-700">
            {t("Anzahl")}: {quantity}
          </p>
          <p className="mt-1 md:mt-0.5 text-gray-700">
            {t("Artikelnummer")}: {number}
          </p>
        </div>
      </div>
    </div>
  );
}
