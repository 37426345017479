import React from "react";

import Article from "./Article";

export default function Display({
  reasonData,
  allProducts,
  selectChecker,
  replace,
}) {
  reasonData;
  const view = allProducts.map((element, index) => {
    if (selectChecker[`${element.id}`] > 0) {
      return (
        <Article
          key={index}
          name={element.articleName}
          number={element.articleNumber}
          price={element.price}
          quantity={selectChecker[`${element.id}`]}
          reason={reasonData[`${element.id}`]}
          replace={replace[`${element.id}`]}
        ></Article>
      );
    }
  });

  return <div className="divide-y divide-gray-200 px-5 md:px-2">{view}</div>;
}
