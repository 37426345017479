import { DocumentDownloadIcon } from "@heroicons/react/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";

export default function DHLLabel({ label, data }) {
  const { t } = useTranslation();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return (
    <div className=" flex flex-row md:justify-center md:w-3/4 my-2">
      <div className="my-2 flex flex-row justify-center w-2/5 md:w-full">
        <a
          className="block"
          name="retourenetikett"
          download={`retourschein-${data[0].number}`}
          href={`data:application/pdf;base64,${label.labelData}`}
        >
          <button
            type="button"
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-shop hover:bg-donkey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shop"
          >
            <DocumentDownloadIcon className="h-6 w-6 "></DocumentDownloadIcon>
            {t("overview.button2")}
          </button>
        </a>
      </div>
    </div>
  );
}
