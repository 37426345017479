import React from "react";

import i18n from "../../i18n";
import Mysvg from "./General/svgs";
import TutPic from "./General/TutPic";
export default function Tutorial() {
  const currLng = () => i18n.language || window.localStorage.i18nextLng || "";

  function showPics() {
    const offset = currLng() === "fr" ? 4 : 0;
    return Object.entries(Mysvg)
      .slice(offset, offset + 4)
      .map((e, i) => {
        return <TutPic key={i} src={e[1]}></TutPic>;
      });
  }

  return <div className="flex m-3 flex-wrap">{showPics()}</div>;
}
