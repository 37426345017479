import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DropDownReplace from "./DropDownReplace";
export default function DropDownReason({
  setReasonSelect,
  id,
  setOther,
  setReplace,
}) {
  const { t } = useTranslation();
  const reason = [
    { id: 1, name: `${t("order.reason.1")}` },
    { id: 2, name: `${t("order.reason.2")}` },
    { id: 3, name: `${t("order.reason.3")}` },
    { id: 4, name: `${t("order.reason.4")}` },
    { id: 5, name: `${t("order.reason.5")}` },
    { id: 6, name: `${t("order.reason.6")}` },
    { id: 7, name: `${t("order.reason.7")}` },
    { id: 8, name: `${t("order.reason.8")}` },
    { id: 9, name: `${t("order.reason.9")}` },
    { id: 10, name: `${t("order.reason.10")}` },
    { id: 11, name: `${t("order.reason.11")}` },
  ];

  const [selected, setSelected] = useState(reason[0]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function onSelection(event, id) {
    setSelected(event);
    setReasonSelect((prevState) => ({ ...prevState, [id]: event.name }));
  }

  useEffect(() => {
    setReasonSelect((prevState) => ({ ...prevState, [id]: reason[0].name }));
  }, []);
  selected.id;
  useEffect(() => {
    if (selected.id === 11) {
      setOther(true);
    } else {
      setOther(false);
    }
  }, [selected]);
  useEffect(() => {
    if (![3, 7, 8].includes(selected.id)) {
      setReplace((prevState) => ({ ...prevState, [id]: `${t("Nein")}` }));
    }
  }, [selected]);
  return (
    <div>
      <Listbox value={selected} onChange={(e) => onSelection(e, id)}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700 min-w-max">
              {t("Rückgabegrund")}
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-50 md:w-56 border border-gray-300  rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-donkey focus:border-donkey sm:text-sm">
                <span className="block truncate w-48">{selected.name}</span>
                <span className="absolute inset-y-0 right-0  flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  bottom-full md:bottom-auto z-10 mt-1  bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {reason.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-donkey" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {person.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-donkey",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div className="mt-4">
        {[3, 7, 8].includes(selected.id) ? (
          <DropDownReplace
            setReplace={setReplace}
            id={id}
            reason={selected}
          ></DropDownReplace>
        ) : null}
      </div>
    </div>
  );
}
