import stepOneDE from "../../../Images/DE_Retour_Illu_1.svg";
import stepTwoDE from "../../../Images/DE_Retour_Illu_2.svg";
import stepThreeDE from "../../../Images/DE_Retour_Illu_3.svg";
import stepFourDE from "../../../Images/DE_Retour_Illu_4.svg";
import stepOneFR from "../../../Images/FR_Retour_Illu_1.svg";
import stepTwoFR from "../../../Images/FR_Retour_Illu_2.svg";
import stepThreeFR from "../../../Images/FR_Retour_Illu_3.svg";
import stepFourFR from "../../../Images/FR_Retour_Illu_4.svg";

const Mysvg = {
  stepOneDE,
  stepTwoDE,
  stepThreeDE,
  stepFourDE,
  stepOneFR,
  stepTwoFR,
  stepThreeFR,
  stepFourFR,
};

export default Mysvg;
